import React from "react";
import styled from "styled-components";
// Components
import GalleryBox from "../Elements/GalleryBox";
import FullButton from "../Buttons/FullButton";

// Assets
import Director1 from "../../assets/img/director1.jpeg";
import Car1 from "../../assets/img/projects/car1.jpeg";
import Car2 from "../../assets/img/projects/car2.jpeg";
import Car3 from "../../assets/img/projects/car3.jpeg";
import Car5 from "../../assets/img/projects/car5.jpeg";

export default function Galleries() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Galeri</h1>
            <p className="font16">
              Antara pilihan kereta yang disediakan di DK Auto Car:
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-lg-12">
              <GalleryBox
                img={Car1}
                // title="Awesome Project"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <GalleryBox
                img={Car2}
                // title="Awesome Project"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <GalleryBox
                img={Car3}
                // title="Awesome Project"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              />
            </div>
          </div> */}
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">Pengarah</h1>
        </HeaderInfo>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={Director1} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Managing Director</h4>
              <h2 className="font30 extraBold">Kamarul Hazimin Bin Mahamad</h2>
              <p className="font12">
                Kamarul Hazimin Bin Mahamad adalah seorang yang membawa banyak
                pengalaman dan semangat kepada peranannya sebagai Pengarah DK
                Auto Car (M) SDN BHD. Beliau telah terlibat dalam dunia
                automotif sejak tahun 2006, dan melalui pengalamannya itu,
                beliau telah menunjukkan kecekapan yang luar biasa dalam menjual
                dan mengurus syarikat kereta. Kamarul dikenali di kalangan rakan
                sekerja dan industri kerana kesungguhannya yang tidak pernah
                pudar terhadap bidang automotif, serta diakui secara meluas
                sebagai individu yang berpengetahuan tinggi dalam bidang ini.
                Dengan visi yang jelas dan komitmen yang kuat, Kamarul berazam
                untuk membawa DK Auto Car ke arah kejayaan yang berkekalan dan
                kecemerlangan dalam pasaran automotif.
              </p>
              {/* <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 140px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
