import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/FooterLogo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper id="contact">
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <LogoImg />
              <h1
                className="font15 extraBold whiteColor"
                style={{ marginLeft: "15px" }}
              >
                DK Auto Car
              </h1>
            </Link>
            <div style={{ padding: "30px 0" }} className="Container">
              <StyleP
                className="whiteColor font15 extraBold"
                style={{ margin: "-10px 0px 0px 0px" }}
              >
                HUBUNGI KAMI
              </StyleP>
              <StyleP
                className="whiteColor font18 semiBold"
                style={{ margin: "20px 0px 0px 0px" }}
              >
                Pejabat
              </StyleP>
              <StyleP
                className="whiteColor font13"
                style={{ margin: "20px 0px 0px 0px" }}
              >
                Lot 6673, Jalan Kebun Nenas,
                <br /> Mukim 11, Bukit Tengah,
                <br /> 14000, Bukit Mertajam,
                <br /> Pulau Pinang
              </StyleP>
              <StyleP
                className="whiteColor font18 semiBold"
                style={{ margin: "20px 0px 0px 0px" }}
              >
                Telefon
              </StyleP>
              <StyleP
                className="whiteColor font13"
                style={{ margin: "20px 0px 20px 0px" }}
              >
                +6012-5488678
              </StyleP>
              <StyleP
                className="whiteColor font13"
                style={{ margin: "0px 0px -30px 0px" }}
              >
                © {getCurrentYear()} -{" "}
                <span className="purpleColor font13">DK Auto Car</span> All
                Right Reserved
              </StyleP>
            </div>
            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */
`;

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
